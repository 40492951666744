import React from "react";

export default (style) => (
  <svg
    style={style}
    width="511"
    height="509"
    viewBox="0 0 511 509"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="2" y="1" width="508" height="508" rx="50" fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M51.8057 0.305664C23.363 0.305664 0.305664 23.363 0.305664 51.8057V85.4692C0.305664 86.2976 0.977237 86.9692 1.80566 86.9692C2.63409 86.9692 3.30566 86.2976 3.30566 85.4692V51.8057C3.30566 25.0199 25.0198 3.30566 51.8057 3.30566H83.6635C84.4919 3.30566 85.1635 2.63409 85.1635 1.80566C85.1635 0.977237 84.4919 0.305664 83.6635 0.305664H51.8057ZM459.194 0.305664C487.637 0.305664 510.694 23.363 510.694 51.8057V85.4692C510.694 86.2976 510.023 86.9692 509.194 86.9692C508.366 86.9692 507.694 86.2976 507.694 85.4692V51.8057C507.694 25.0199 485.98 3.30566 459.194 3.30566H427.337C426.508 3.30566 425.837 2.63409 425.837 1.80566C425.837 0.977237 426.508 0.305664 427.337 0.305664H459.194ZM510.694 457.194C510.694 485.637 487.637 508.694 459.194 508.694H427.337C426.508 508.694 425.837 508.023 425.837 507.194C425.837 506.366 426.508 505.694 427.337 505.694H459.194C485.98 505.694 507.694 483.98 507.694 457.194V423.531C507.694 422.702 508.366 422.031 509.194 422.031C510.023 422.031 510.694 422.702 510.694 423.531V457.194ZM51.8057 508.694C23.363 508.694 0.305664 485.637 0.305664 457.194V423.531C0.305664 422.702 0.977237 422.031 1.80566 422.031C2.63409 422.031 3.30566 422.702 3.30566 423.531V457.194C3.30566 483.98 25.0198 505.694 51.8057 505.694H83.6635C84.4919 505.694 85.1635 506.366 85.1635 507.194C85.1635 508.023 84.4919 508.694 83.6635 508.694H51.8057Z"
      fill="#6367EF"
    />
  </svg>
);
