import React from "react";
import Hls from "hls.js";

class HlsVideo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      playerId: Date.now(),
    };

    this.hls = null;
  }

  componentDidUpdate() {
    this._initPlayer();
  }

  componentDidMount() {
    this._initPlayer();
  }

  componentWillUnmount() {
    let { hls } = this;

    if (hls) {
      hls.destroy();
    }
  }

  _initPlayer() {
    if (this.hls) {
      this.hls.destroy();
    }

    let { url, autoplay, hlsConfig } = this.props;
    const $video = this.props.videoRef.current;

    let hls = new Hls(hlsConfig);

    hls.loadSource(url);
    hls.attachMedia($video);
    hls.on(Hls.Events.MANIFEST_PARSED, () => {
      if (autoplay) {
        $video.play();
      }
    });

    this.hls = hls;
  }

  render() {
    let { playerId } = this.state;
    const { videoProps } = this.props;

    return (
      <div key={playerId} className="player-area">
        <video
          ref={this.props.videoRef}
          className="hls-player"
          id={`react-hls-${playerId}`}
          {...videoProps}
        ></video>
      </div>
    );
  }
}

export default HlsVideo;
