import React from "react";

export default (style) => (
  <svg
    style={style}
    width="194"
    height="27"
    viewBox="0 0 194 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M53.9193 9.6328C53.9193 8.11303 53.0777 7.35346 51.3944 7.35346H49.2621V11.8635H51.3944C53.0777 11.8635 53.9193 11.1197 53.9193 9.6328V9.6328ZM49.2621 15.2219V21.3004H45.0703V3.97046H51.8603C53.9193 3.97046 55.4877 4.48126 56.5668 5.50223C57.6452 6.52382 58.1844 7.90027 58.1844 9.63282C58.1844 10.7112 57.9432 11.6716 57.4615 12.5132C56.9797 13.3549 56.2637 14.0166 55.3166 14.499C54.3689 14.9807 53.2166 15.2219 51.8603 15.2219H49.2621Z"
      fill="#6367EF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M66.8867 18.0895H72.4751V21.3008H62.6948V3.97021H66.8867V18.0895Z"
      fill="#6367EF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M86.521 14.9521L84.3642 8.57819L82.1821 14.9521H86.521ZM87.5992 18.1388H81.104L80.025 21.3008H75.6128L81.9615 4.09277H86.7898L93.1391 21.3008H88.6776L87.5992 18.1388Z"
      fill="#6367EF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M112.185 3.97021L106.18 15.5405V21.3008H101.964V15.5405L95.9585 3.97021H100.763L104.097 11.0299L107.43 3.97021H112.185Z"
      fill="#6367EF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M125.766 15.9819C125.766 15.3612 125.561 14.8744 125.152 14.5227C124.744 14.1717 124.164 13.9961 123.412 13.9961H120.446V17.8931H123.437C124.99 17.8931 125.766 17.2567 125.766 15.9819V15.9819ZM120.446 11.0545H123.191C124.646 11.0545 125.373 10.4338 125.373 9.19122C125.373 7.9499 124.63 7.3286 123.143 7.3286H120.446V11.0545ZM129.135 13.8983C129.748 14.6661 130.055 15.557 130.055 16.5703C130.055 18.0573 129.56 19.2172 128.572 20.0506C127.583 20.884 126.166 21.3008 124.319 21.3008H116.255V3.97021H124.123C125.887 3.97021 127.253 4.3541 128.217 5.12251C129.181 5.89092 129.663 6.98513 129.663 8.40703C129.663 9.45326 129.385 10.3233 128.83 11.0179C128.274 11.7124 127.546 12.1664 126.648 12.3779C127.693 12.6235 128.523 13.1299 129.135 13.8983V13.8983Z"
      fill="#6367EF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M139.677 8.93431C138.868 9.82521 138.463 11.0388 138.463 12.5743C138.463 14.0941 138.868 15.3038 139.677 16.2023C140.486 17.1014 141.577 17.5503 142.949 17.5503C144.305 17.5503 145.392 17.1014 146.209 16.2023C147.026 15.3038 147.435 14.0941 147.435 12.5743C147.435 11.0545 147.03 9.84542 146.221 8.94694C145.413 8.04783 144.321 7.59828 142.949 7.59828C141.577 7.59828 140.486 8.04341 139.677 8.93431M147.398 4.84098C148.73 5.60055 149.779 6.65498 150.547 8.00238C151.316 9.35104 151.699 10.8746 151.699 12.5743C151.699 14.274 151.311 15.802 150.535 17.1583C149.759 18.5151 148.705 19.5727 147.373 20.3329C146.04 21.0925 144.566 21.4719 142.949 21.4719C141.331 21.4719 139.856 21.0925 138.524 20.3329C137.191 19.5727 136.138 18.5151 135.362 17.1583C134.586 15.802 134.197 14.274 134.197 12.5743C134.197 10.8746 134.586 9.35104 135.362 8.00238C136.138 6.65498 137.191 5.60055 138.524 4.84098C139.856 4.08078 141.331 3.70068 142.949 3.70068C144.583 3.70068 146.065 4.08078 147.398 4.84098"
      fill="#6367EF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M161.468 8.93431C160.659 9.82521 160.253 11.0388 160.253 12.5743C160.253 14.0941 160.659 15.3038 161.468 16.2023C162.276 17.1014 163.367 17.5503 164.74 17.5503C166.096 17.5503 167.182 17.1014 168 16.2023C168.817 15.3038 169.226 14.0941 169.226 12.5743C169.226 11.0545 168.821 9.84542 168.012 8.94694C167.203 8.04783 166.112 7.59828 164.74 7.59828C163.367 7.59828 162.276 8.04341 161.468 8.93431M169.189 4.84098C170.52 5.60055 171.57 6.65498 172.338 8.00238C173.107 9.35104 173.491 10.8746 173.491 12.5743C173.491 14.274 173.102 15.802 172.326 17.1583C171.55 18.5151 170.496 19.5727 169.164 20.3329C167.831 21.0925 166.358 21.4719 164.74 21.4719C163.122 21.4719 161.647 21.0925 160.315 20.3329C158.982 19.5727 157.929 18.5151 157.153 17.1583C156.377 15.802 155.988 14.274 155.988 12.5743C155.988 10.8746 156.377 9.35104 157.153 8.00238C157.929 6.65498 158.982 5.60055 160.315 4.84098C161.647 4.08078 163.122 3.70068 164.74 3.70068C166.373 3.70068 167.856 4.08078 169.189 4.84098"
      fill="#6367EF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M186.459 12.452L193.445 21.301H188.37L182.488 13.5304V21.301H178.296V3.97046H182.488V11.5939L188.42 3.97046H193.445L186.459 12.452Z"
      fill="#6367EF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 6.19336C0 9.60858 2.77814 12.3861 6.19336 12.3861C9.60795 12.3861 12.3855 9.60858 12.3855 6.19336C12.3855 2.7794 9.60795 0 6.19336 0C2.77814 0 0 2.7794 0 6.19336M3.52578 6.19334C3.52578 4.72345 4.72228 3.52632 6.19343 3.52632C7.66395 3.52632 8.85982 4.72345 8.85982 6.19334C8.85982 7.66512 7.66395 8.86099 6.19343 8.86099C4.72228 8.86099 3.52578 7.66512 3.52578 6.19334"
      fill="#6367EF"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="13"
      height="13"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 6.19336C0 9.60795 2.77814 12.3867 6.19336 12.3867V12.3867C9.60795 12.3867 12.3861 9.60795 12.3861 6.19336V6.19336C12.3861 2.7794 9.60795 0 6.19336 0V0C2.77814 0 0 2.7794 0 6.19336V6.19336ZM3.52579 6.19337C3.52579 4.72348 4.72229 3.52635 6.19344 3.52635V3.52635C7.66396 3.52635 8.85982 4.72348 8.85982 6.19337V6.19337C8.85982 7.66452 7.66396 8.86102 6.19344 8.86102V8.86102C4.72229 8.86102 3.52579 7.66452 3.52579 6.19337V6.19337Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 12.3861H12.3855V0H0V12.3861Z"
        fill="#6367EF"
      />
    </g>
    <mask
      id="mask1"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="17"
      y="0"
      width="12"
      height="13"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.6753 0.833376C16.9871 1.52223 16.9871 2.63854 17.6753 3.32739V3.32739L20.5425 6.1933L17.6753 9.06046C16.9871 9.74932 16.9871 10.865 17.6753 11.5526V11.5526C18.0201 11.898 18.4709 12.0697 18.923 12.0697V12.0697C19.3732 12.0697 19.8246 11.898 20.1687 11.5526V11.5526L23.0353 8.68668L25.9037 11.5526C26.2472 11.898 26.6974 12.0697 27.1494 12.0697V12.0697C27.6009 12.0697 28.0511 11.898 28.3958 11.5526V11.5526C29.0847 10.865 29.0847 9.74932 28.3958 9.06046V9.06046L25.5299 6.1933L28.3958 3.32739C29.0847 2.63854 29.0847 1.52223 28.3958 0.833376V0.833376C27.7082 0.145786 26.5913 0.144523 25.9037 0.833376V0.833376L23.0353 3.70118L20.1687 0.833376C19.8246 0.489266 19.3732 0.316895 18.9224 0.316895V0.316895C18.4709 0.316895 18.0201 0.489266 17.6753 0.833376V0.833376Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.9873 12.0697H29.0848V0.144531H16.9873V12.0697Z"
        fill="#6367EF"
      />
    </g>
    <mask
      id="mask2"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="16"
      width="30"
      height="11"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.3641 16.3652L22.0086 16.7857C21.1493 16.8532 20.4661 17.5313 20.3903 18.3894V18.3894C20.3152 19.2481 20.8714 20.0348 21.7055 20.2508V20.2508C22.0086 20.3291 22.299 20.4358 22.5768 20.5646V20.5646C20.247 22.424 17.3299 23.4734 14.2702 23.4734V23.4734C9.8378 23.4734 5.70974 21.2774 3.2233 17.5989V17.5989C2.67904 16.7926 1.58357 16.5805 0.776641 17.126V17.126C-0.0302823 17.6709 -0.243063 18.7657 0.302463 19.5733V19.5733C3.44366 24.2229 8.66593 26.9998 14.2702 26.9998V26.9998C18.2733 26.9998 22.0768 25.5766 25.0709 23.0725V23.0725C25.1952 23.3433 25.2982 23.6249 25.3752 23.9198V23.9198C25.5772 24.7034 26.2838 25.2407 27.0806 25.2407V25.2407C27.1317 25.2407 27.1835 25.2388 27.2347 25.235V25.235C28.0934 25.1592 28.7715 24.4748 28.8384 23.6155V23.6155L29.2589 18.26C29.2993 17.7473 29.1131 17.2415 28.7487 16.8766V16.8766C28.416 16.5451 27.9677 16.3601 27.503 16.3601V16.3601C27.4569 16.3601 27.4108 16.3626 27.3641 16.3652V16.3652Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask2)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M-0.243164 26.9998H29.2992V16.3601H-0.243164V26.9998Z"
        fill="#6367EF"
      />
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.6753 0.833376C16.9871 1.52286 16.9871 2.63854 17.6753 3.32739L20.5425 6.1933L17.6753 9.06046C16.9871 9.74932 16.9871 10.8656 17.6753 11.5532C18.0201 11.8973 18.4709 12.0697 18.923 12.0697C19.3732 12.0697 19.8246 11.8973 20.1687 11.5532L23.0359 8.68668L25.9037 11.5532C26.2472 11.8973 26.698 12.0697 27.1488 12.0697C27.6009 12.0697 28.0511 11.8973 28.3958 11.5532C29.0847 10.8656 29.0847 9.74932 28.3958 9.06046L25.5293 6.1933L28.3958 3.32739C29.0847 2.63854 29.0847 1.52286 28.3958 0.833376C27.7082 0.145786 26.5913 0.144523 25.9037 0.833376L23.0359 3.70118L20.1687 0.833376C19.8246 0.489266 19.3732 0.316895 18.9224 0.316895C18.4709 0.316895 18.0201 0.489266 17.6753 0.833376"
      fill="#6367EF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.3643 16.3654L22.0088 16.7859C21.1494 16.8528 20.4663 17.5316 20.3905 18.3897C20.3154 19.2477 20.8716 20.0351 21.7057 20.2504C22.0088 20.3287 22.2992 20.4354 22.577 20.5648C20.2478 22.4243 17.3307 23.4737 14.2704 23.4737C9.83797 23.4737 5.70927 21.277 3.22347 17.5992C2.67857 16.7929 1.58373 16.5807 0.776808 17.1256C-0.0307473 17.6705 -0.242896 18.766 0.30263 19.5729C3.44382 24.2225 8.6661 27 14.2704 27C18.274 27 22.0769 25.5768 25.071 23.0727C25.1954 23.3436 25.299 23.6246 25.3754 23.9201C25.5774 24.7036 26.2839 25.2409 27.0808 25.2409C27.1319 25.2409 27.1837 25.239 27.2348 25.2353C28.0935 25.1595 28.7716 24.4751 28.8386 23.6157L29.2591 18.2602C29.2995 17.7469 29.1132 17.2418 28.7489 16.8768C28.4162 16.5447 27.9685 16.3604 27.5032 16.3604C27.4571 16.3604 27.411 16.3622 27.3643 16.3654"
      fill="#6367EF"
    />
  </svg>
);
