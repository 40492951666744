import React, { useEffect, useRef, useState } from "react";
import Pusher, { Channel } from "pusher-js";
import { QRCode } from "react-qr-svg";
const backgroundImage = require("./pattern.png");
import CodeBackground from "./CodeBackground";
import Instructions from "./Instructions";
import Logo from "./Logo";
import Refresh from "./Refresh";
import MuteWarning from "./MuteWarning";
import HlsVideo from "./HlsVideo";
import qs from "qs";

enum CastEvent {
  Cast = "client-cast",
  Play = "client-play",
  Pause = "client-pause",
  SeekRelative = "client-seek-relative",
}

enum StorageKeys {
  ChannelId = "channel-id",
}

// whatever works
function getUid() {
  const randomNumber = window.crypto
    ? window.crypto.getRandomValues(new Uint32Array(1))[0]
    : Math.round(Math.random() * 1000000);

  return Number(`${Date.now() - 1612787927811}${randomNumber}`)
    .toString(32)
    .toUpperCase();
}

const PUSHER_CONFIG = {
  authEndpoint: process.env.PUSHER_AUTH_ENDPOINT,
  cluster: process.env.PUSHER_APP_CLUSTER,
};

const PUSHER_APP_KEY = process.env.PUSHER_APP_KEY;

const pusher = new Pusher(PUSHER_APP_KEY, PUSHER_CONFIG);

export default function App() {
  const [muteWarning, setMuteWarning] = useState(true);
  const [channelId, setChannelId] = useState("");
  const [videoToCastUri, setVideoToCast] = useState("");
  const channelRef = useRef<Channel>();
  const videoRef = useRef<HTMLVideoElement | null>(null);

  function setUp(clean) {
    let channelId = sessionStorage.getItem(StorageKeys.ChannelId);
    if (channelId && !clean) {
      setChannelId(channelId);
    } else {
      channelId = getUid();
      sessionStorage.setItem(StorageKeys.ChannelId, channelId);
      setChannelId(channelId);
    }

    document.title = `Playbook`;

    if (channelRef.current) {
      channelRef.current.unsubscribe();
    }

    channelRef.current = pusher.subscribe(`private-${channelId}`);

    channelRef.current.bind_global((event, data) => {
      console.log(`event received on ${channelId}`, event, data);
    });

    channelRef.current.bind(CastEvent.Cast, function ({ url }) {
      try {
        setVideoToCast(url);
      } catch (e) {
        console.log(e);
      }
    });

    channelRef.current.bind(CastEvent.Pause, function () {
      try {
        videoRef?.current?.pause();
      } catch (e) {
        console.log(e);
      }
    });

    channelRef.current.bind(CastEvent.Play, function () {
      try {
        videoRef?.current?.play();
      } catch (e) {
        console.log(e);
      }
    });

    channelRef.current.bind(CastEvent.SeekRelative, function ({ position }) {
      if (videoRef?.current?.currentTime) {
        try {
          videoRef?.current?.play();
          videoRef.current.currentTime =
            videoRef?.current?.currentTime + position;
        } catch (e) {
          console.log(e);
        }
      }
    });
  }

  useEffect(() => {
    setUp(false);
  }, []);

  const videoProps = {
    muted: true,
    controlsList: "nodownload",
    autoPlay: true,
    width: "100%",
    height: "100%",
    controls: true,
  };

  return videoToCastUri ? (
    <div style={{ backgroundColor: "black" }}>
      {videoToCastUri.includes(`m3u8`) ? (
        <HlsVideo
          hlsConfig={{
            liveDurationInfinity: true,
          }}
          videoRef={videoRef}
          videoProps={videoProps}
          url={videoToCastUri}
        />
      ) : (
        <video ref={videoRef} {...videoProps} src={videoToCastUri} />
      )}
      {muteWarning ? (
        <div
          onClick={() => {
            if (videoRef?.current?.play) {
              if (videoRef.current.muted) {
                videoRef.current.muted = false;
                videoRef?.current?.play();
              }
            }
            setMuteWarning(false);
          }}
          style={{
            position: "absolute",
            width: "100vw",
            height: "100vh",
            top: 0,
            left: 0,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <MuteWarning />
        </div>
      ) : null}
    </div>
  ) : (
    <>
      <div
        style={{
          background: `url(${backgroundImage})`,
          width: "100vw",
          height: "100vh",
          backgroundRepeat: "repeat",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            transform: `scale(0.7)`,
            justifyContent: "center",
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Logo />
          <div style={{ height: 125 }} />
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Instructions />
            <div style={{ width: 151 }} />
            <div style={{ position: "relative", width: 511, height: 509 }}>
              <CodeBackground />
              <QRCode
                style={{
                  position: "absolute",
                  top: 73,
                  left: 74,
                  width: 362,
                  height: 362,
                }}
                bgColor="#FFFFFF"
                fgColor="#000000"
                value={
                  `${process.env.PLAYBOOK_APP_LINK_URL}/cast2web?` +
                  qs.stringify({
                    channelId: `private-${channelId}`,
                    pci: `private-${channelId}`,
                    pae: PUSHER_CONFIG.authEndpoint,
                    pc: PUSHER_CONFIG.cluster,
                    v: 1,
                    pak: PUSHER_APP_KEY,
                  })
                }
              />
              <div
                onClick={() => setUp(true)}
                style={{
                  position: "absolute",
                  top: 12 + 508,
                  left: 207,
                }}
              >
                <Refresh />
              </div>
            </div>
          </div>
          <div style={{ height: 125 }} />
        </div>
      </div>
    </>
  );
}
